<template>
  <v-layout class="column sheet-form">
    <div class="form-main-header">
      <h1>
        {{ formName }}
      </h1>
      <v-divider />
    </div>
    <slot name="top" />
    <v-form ref="industry" class="customer-account">
      <div class="inner-container">
        <CRInput
          id="industry-input"
          v-model="model.label"
          :floatinglabel="'Industry Name'"
          :disabled="status"
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Industry Name Is Required',
              error: 'Industry Name Is Required',
            }),
          ]"
        />
        <CRSelect
          id="parent-industry-select"
          v-model="model.parentIndustryId"
          :label="'Parent Industry'"
          :disabled="status"
          item-value="industryId"
          item-text="label"
          placeholder="Select a parent industry (optional)"
          solo
          flat
          :items="availableParentIndustries"
          @change="getAvailableIndustries"
        />

        <CRSelect
          id="sub-industry-select"
          v-model="model.subIndustryIds"
          :label="'Sub-Industries'"
          :disabled="status"
          item-value="industryId"
          item-text="label"
          placeholder="Select sub-industries (optional)"
          solo
          flat
          :items="availableSubIndustries"
          multiple
          @change="getAvailableIndustries"
        />

        <v-checkbox
          id="industry-other-checkbox"
          v-model="model.isOther"
          :disabled="status"
          label="Is this an 'Other' industry? (Requires industry description)"
        />

        <div class="button-container">
          <v-btn
            id="industries-form-button-cancel"
            class="btn-secondaryaction"
            @click="cancelAction"
          >
            {{ isModeView ? 'All Industries' : 'Cancel' }}
          </v-btn>

          <v-btn
            v-if="isModeEdit"
            id="industries-form-button-save"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Industry
          </v-btn>

          <v-btn
            v-if="isModeAdd"
            id="industries-form-button-add"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Add Industry
          </v-btn>

          <v-btn
            v-if="isModeView"
            id="industries-form-edit"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Industry
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-layout>
</template>
<script>
import industries from '@/services/industries'
import { authComputed } from '@/state/helpers'
import * as logger from '@/utils/logger'
import { sort } from '@/utils/sort'
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      model: {
        label: '',
        parentIndustryId: null,
        industryTypeId: 1,
        subIndustryIds: [],
        isOther: false,
      },
      status: true,
      showOptions: false,
      loading: false,
      availableParentIndustries: [],
      availableSubIndustries: [],
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    formName() {
      if (this.isModeEdit) {
        return 'Edit Industry'
      }
      if (this.isModeView) {
        return 'View Industry'
      }

      return 'Industry'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
  },
  watch: {
    $route: async function (to, from) {
      if (to.path === from.path) {
        return
      }
      if (
        to.params.id?.toString() === from.params.id?.toString() &&
        !to.params?.subRoute
      ) {
        return
      }
      this.loadIndustry()
      this.getAvailableIndustries()
    },
  },
  async mounted() {
    if (this.isModeEdit || this.isModeAdd) {
      this.status = false
    }

    if (this.isModeEdit || this.isModeView) {
      this.loadIndustry()
    }

    this.getAvailableIndustries()
  },
  methods: {
    async loadIndustry() {
      const data = await industries.getIndustry(this.id).then((data) => data.data?.industry)

      this.model.subIndustryIds = data.subIndustryIds
      this.model.label = data.label
      this.model.parentIndustryId = data.parentIndustryId
      this.model.isOther = data.isOther
      this.getAvailableIndustries()
    },
    async getAvailableIndustries() {
      const industrySort = sort()

      industrySort.add({
        prop: 'label',
        direction: 'asc',
      })

      const sortParams = industrySort.asQueryParams()
      const availableParentIndustriesData = await industries
        .getIndustries({
          page: 1,
          pageSize: -1,
          sorts: sortParams,
        })
        .then((data) => data.data)
      this.availableParentIndustries = availableParentIndustriesData.resultList

      this.availableSubIndustries = this.availableParentIndustries.filter(
        (industry) => {
          if (
            (industry.parentIndustryId === null ||
              industry.parentIndustryId === parseInt(this.id)) &&
            industry.industryId !== this.model.parentIndustryId &&
            industry.industryId !== parseInt(this.id)
          ) {
            return industry
          }
        }
      )

      this.availableSubIndustries.sort((a, b) => a.label - b.label)

      this.availableParentIndustries = this.availableParentIndustries.filter(
        (industry) => {
          if (
            industry.parentIndustryId != parseInt(this.id) &&
            industry.industryId !== parseInt(this.id) &&
            !this.model.subIndustryIds.includes(industry.industryId)
          ) {
            return industry
          }
        }
      )
    },
    async submit() {
      let id
      this.loading = true

      const formIsValid = this.$refs.industry.validate()
      if (!formIsValid) {
        this.loading = false
        return
      }

      if (
        this.model.subIndustryIds.length === 0 &&
        this.model.parentIndustryId
      ) {
        this.model.industryTypeId = 0
      }
      const newIndustry = JSON.parse(JSON.stringify(this.model))

      if (this.isModeAdd) {
        newIndustry.companyId = this.currentUser?.companyId
      }

      try {
        if (this.isModeAdd) {
          id = await industries
            .addIndustry(newIndustry)
            .then((data) => data.data)
        }

        if (this.isModeEdit) {
          await industries.modifyIndustry({ id: this.id, payload: newIndustry })
        }
      } catch (e) {
        logger.error(e)
      }

      this.loading = false
      this.status = true
      await this.getAvailableIndustries()
      this.$router.push(`/industries/view/${this.id || id}`)
      this.$store.dispatch('app/showAlert', {
        message: 'Industry saved.',
        type: 'success',
      })
    },
    backToTable() {
      this.$router.push('/industries')
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.$router.push(`/industries/edit/${this.id}`)
      }

      this.status = !this.status
    },
    disableAndRevert() {
      this.status = true
      this.$router.push(`/industries/view/${this.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: center;
}

.width-30 {
  flex: 1 1 50%;
  max-width: 400px;
  margin-right: 3%;
}

.input-space {
  margin: 2% 0;
}

.box-height {
  max-height: 80px;
}

.empty {
  height: 67px;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.toggle-off {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .form-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.card {
  flex-direction: column;
  padding: 30px;
  background-color: $white;
  border-radius: 15px;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

.row-block-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-block {
  // max-width: 48%;
  max-width: 30%;
}

.checkbox {
  display: flex;
}

.base-form-title {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.03em;
}

.is-disabled-true ::v-deep textarea {
  cursor: not-allowed;
}

.customer-account {
  display: flex;
  justify-content: center;

  .inner-container {
    width: 50%;

    .spacing {
      margin-top: 30px;
      margin-bottom: 5%;
    }

    .justify-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .input-space.description {
  margin-top: 20px;
}

::v-deep input[disabled],
::v-deep input:disabled,
::v-deep textarea[disabled] {
  cursor: not-allowed;
  background-color: $blue-dull !important;
}
</style>
